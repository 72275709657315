/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Adrijan Mahmuti - Software Developer",
  description:
    "Discover the professional portfolio of Adrijan Mahmuti, a passionate software developer and web developer dedicated to creating sustainable and scalable social and technical systems. Explore end-to-end product development projects and innovative solutions.",
  og: {
    title: "Adrijan Mahmuti - Software Developer",
    type: "website",
    url: "http://mahmutiadrijan.com/",
  },
};

//Home Page
const greeting = {
  title: "Adrijan Mahmuti",
  logo_name: "Adrijan Mahmuti",
  nickname: "",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1Uv0TzFVNYROpBRbamDZsi34qlIgkTZIk/view",
  portfolio_repository: "https://github.com/AdrijanM/AM-portfolio", //TODO
  githubProfile: "https://github.com/AdrijanM",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/AdrijanM",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/adrijanm/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:mahmutiadrijan@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using Angular, JavaScript, TypeScript, HTML5, CSS3",
        "⚡ Creating application backend in NodeJS, NestJs, .NET",
        "⚡ Implementing Blockchain technology on web3 websites",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "TypeScript",
          fontAwesomeClassname: "simple-icons:typescript",
          style: {
            color: "#3178C6",
          },
        },
        {
          skillName: "Angular",
          fontAwesomeClassname: "simple-icons:angular",
          style: {
            color: "#DD0031",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NestJs",
          fontAwesomeClassname: "simple-icons:nestjs",
          style: {
            color: "#E0234E",
          },
        },
        {
          skillName: ".NET",
          fontAwesomeClassname: "simple-icons:dotnet",
          style: {
            color: "#512BD4",
          },
        },
        {
          skillName: "Blockchain",
          fontAwesomeClassname: "simple-icons:blockchaindotcom",
          style: {
            color: "#121D33",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Strapi",
          fontAwesomeClassname: "simple-icons:strapi",
          style: {
            color: "#2F2E8B",
          },
        },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#21759B",
          },
        },
      ],
    },
    // {
    //   title: "Cloud Infra-Architecture",
    //   fileName: "CloudInfraImg",
    //   skills: [
    //     "⚡ Experience working on multiple cloud platforms",
    //     "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
    //     "⚡ Deploying deep learning models on cloud to use on mobile devices",
    //     "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "GCP",
    //       fontAwesomeClassname: "simple-icons:googlecloud",
    //       style: {
    //         color: "#4285F4",
    //       },
    //     },
    //     {
    //       skillName: "AWS",
    //       fontAwesomeClassname: "simple-icons:amazonaws",
    //       style: {
    //         color: "#FF9900",
    //       },
    //     },
    //     {
    //       skillName: "Azure",
    //       fontAwesomeClassname: "simple-icons:microsoftazure",
    //       style: {
    //         color: "#0089D6",
    //       },
    //     },
    //     {
    //       skillName: "Firebase",
    //       fontAwesomeClassname: "simple-icons:firebase",
    //       style: {
    //         color: "#FFCA28",
    //       },
    //     },
    //     {
    //       skillName: "PostgreSQL",
    //       fontAwesomeClassname: "simple-icons:postgresql",
    //       style: {
    //         color: "#336791",
    //       },
    //     },
    //     {
    //       skillName: "MongoDB",
    //       fontAwesomeClassname: "simple-icons:mongodb",
    //       style: {
    //         color: "#47A248",
    //       },
    //     },
    //     {
    //       skillName: "Docker",
    //       fontAwesomeClassname: "simple-icons:docker",
    //       style: {
    //         color: "#1488C6",
    //       },
    //     },
    //     {
    //       skillName: "Kubernetes",
    //       fontAwesomeClassname: "simple-icons:kubernetes",
    //       style: {
    //         color: "#326CE5",
    //       },
    //     },
    //   ],
    // },
    // {
    //   title: "UI/UX Design",
    //   fileName: "DesignImg",
    //   skills: [
    //     "⚡ Designing highly attractive user interface for mobile and web applications",
    //     "⚡ Customizing logo designs and building logos from scratch",
    //     "⚡ Creating the flow of application functionalities to optimize user experience",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Adobe XD",
    //       fontAwesomeClassname: "simple-icons:adobexd",
    //       style: {
    //         color: "#FF2BC2",
    //       },
    //     },
    //     {
    //       skillName: "Figma",
    //       fontAwesomeClassname: "simple-icons:figma",
    //       style: {
    //         color: "#F24E1E",
    //       },
    //     },
    //     {
    //       skillName: "Adobe Illustrator",
    //       fontAwesomeClassname: "simple-icons:adobeillustrator",
    //       style: {
    //         color: "#FF7C00",
    //       },
    //     },
    //     {
    //       skillName: "Inkscape",
    //       fontAwesomeClassname: "simple-icons:inkscape",
    //       style: {
    //         color: "#000000",
    //       },
    //     },
    //   ],
    // },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    // {
    //   siteName: "LeetCode",
    //   iconifyClassname: "simple-icons:leetcode",
    //   style: {
    //     color: "#F79F1B",
    //   },
    //   profileLink: "https://leetcode.com/layman_brother/",
    // },
    // {
    //   siteName: "HackerRank",
    //   iconifyClassname: "simple-icons:hackerrank",
    //   style: {
    //     color: "#2EC866",
    //   },
    //   profileLink: "https://www.hackerrank.com/layman_brother",
    // },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/users/ashutosh_1919",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/layman_brother",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@ashutosh391",
    // },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/laymanbrother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "South East European University (SEEU)",
      subtitle: "Bachelor's degree, Computer Science",
      logo_path: "SEEU-logo-preview.png",
      alt_name: "SEEU",
      duration: "2018 - 2021",
      descriptions: [
        "⚡ South East European University (SEEU) provided me with a comprehensive education in computer science. I gained a strong theoretical foundation in subjects such as data structures, algorithms, database management systems, operating systems, and artificial intelligence.",
        "⚡ Additionally, I had the opportunity to apply my knowledge through practical projects and coursework. I developed skills in software engineering, programming languages, and web development, including full stack development.",
        "⚡ Throughout my studies, I actively engaged in collaborative projects and problem-solving activities, enhancing my critical thinking and teamwork skills.",
        "⚡ I also had the privilege of participating in workshops and seminars, keeping up with the latest advancements in the field and expanding my knowledge beyond the curriculum.",
      ],
      website_link: "https://seeu.edu.mk/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Angular",
      subtitle: "",
      logo_path: "Angular.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-0599ca1d-7ab6-44cc-add1-fe74db7320b5/",
      alt_name: "Angular",
      color_code: "#fff",
    },
    {
      title: "ASP.NET MVC",
      subtitle: "",
      logo_path: "Net_Framework.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-41b7fb3b-5265-4291-bb3d-6991059b1ee2/",
      alt_name: "ASP.NET",
      color_code: "#fff",
    },
    {
      title: "Python for Data Science and Machine Learning Bootcamp",
      subtitle: "",
      logo_path: "Python.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-cd9c4cc4-c400-4fee-ae17-a23bec97defb/",
      alt_name: "Python",
      color_code: "#fff",
    },
    {
      title: "NestJs in Practice (with MongoDb)",
      subtitle: "",
      logo_path: "nestjs.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-d5bd0d51-9dc8-4887-98f3-7513351557d3/",
      alt_name: "Nestjs",
      color_code: "#fff",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "",
  description:
    "As a skilled Full Stack Developer with a diverse technology stack encompassing Angular, Node.js, NestJS and .NET, I excel in creating scalable applications, integrating APIs, and ensuring robust data security. I have a proven track record of delivering seamless user experiences and optimizing solutions for various projects.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Team Leader",
          company: "Checkmate",
          company_url: "https://www.checkmate.mk/",
          logo_path: "Checkmate-logo.jpeg",
          duration: "February 2024 - Present",
          location: "Tetovo,North Macedonia",
          descriptions: [
            "Leading a team in developing robust server-side applications using NestJS, focusing on modular architecture and scalable design. Expert in optimizing PostgreSQL databases, enhancing performance and reliability for enterprise-level applications. Proficient in leveraging Amazon Web Services (AWS) to deploy secure, scalable, and cost-efficient solutions. Skilled in software project management, driving projects from concept through delivery, ensuring tight integration with React front-ends and superior user experiences.",
          ],
          color: "#0879bf",
        },
        {
          title: "Back End Developer",
          company: "ConcreteEasy",
          company_url: "",
          logo_path: "programmer.svg",
          duration: "February 2024 - Present",
          location: "Tetovo,North Macedonia",
          descriptions: [
            "Specialized in back end development with a strong command of NestJS frameworks, creating efficient, scalable server solutions. Experienced in managing and optimizing PostgreSQL databases to ensure robust data storage and retrieval systems. Proficient with Amazon Web Services (AWS) to ensure high availability and scalability of backend infrastructures. Committed to developing secure and performant applications that meet the dynamic needs of modern businesses.",
          ],
          color: "#0879bf",
        },
        {
          title: "Back End Developer",
          company: "Coordea",
          company_url: "https://coordea.com/",
          logo_path: "Coordea-logo.jpg",
          duration: "May 2023 - May 2024",
          location: "Tetovo,North Macedonia",
          descriptions: [
            "Experienced in scalable server-side app development using .NET. Optimizing SQL databases for ERP systems, ensuring seamless integration with Angular UIs. Emphasizing data security and API integration for enriched user experiences.",
          ],
          color: "#0879bf",
        },
        {
          title: "Freelance Web Developer",
          company: "Upwork",
          company_url: "https://upwork.com/",
          logo_path: "Upwork-logo.png",
          duration: "Dec 2021 - Present",
          location: "Remote",
          descriptions: [
            "Skilled in front-end development for seamless user experiences. Proficient in scalable back-end app creation. WordPress customization expert, including theme, plugin, and website management. Familiar with responsive web design principles. API and third-party service integration for enhanced functionality. Proficient in Git and agile methodologies.",
          ],
          color: "#0879bf",
        },
        {
          title: "Back End Developer",
          company: "Dynamica Lab",
          company_url: "https://dynamicalab.com/",
          logo_path: "programmer.svg",
          duration: "Aug 2022 - Apr 2023",
          location: "Remote",
          descriptions: [
            "I developed high-performance server-side apps, integrated blockchain for secure data management, and ensured seamless integration with Next.js. I prioritized code quality, security, and user experience, staying updated with emerging technologies.",
          ],
          color: "#9b1578",
        },
        {
          title: "Web Developer",
          company: "iSolve",
          company_url: "https://i-solve.info/",
          logo_path: "i-solve.jpeg",
          duration: "Mar 2021 - Sep 2022",
          location: "Tetovo,North Macedonia",
          descriptions: [
            "As a web developer, I crafted responsive interfaces, collaborated on RESTful APIs, utilized .NET, C#, Angular, and Node.js for robust server-side apps, and actively improved development processes. I adeptly troubleshooted and resolved complex issues while staying informed about emerging technologies and industry trends.",
          ],
          color: "#fc1f20",
        },
        {
          title: "IT Support",
          company: "South East European University",
          company_url: "https://seeu.edu.mk/",
          logo_path: "SEEU-logo-preview.png",
          duration: "Oct 2018 - Dec 2020",
          location: "Tetovo, North Macedonia",
          descriptions: [
            "Proficient in hardware/software installation, network troubleshooting, OS/application support (Windows, macOS, Linux), system optimization, virus/malware detection, data backup, and recovery.",
          ],
          color: "#fc1f20",
        },
      ],
    },
    // {
    //   title: "Internships",
    //   experiences: [
    //     {
    //       title: "Machine Learning Intern",
    //       company: "TikTok Inc.",
    //       company_url: "https://www.tiktok.com/en/",
    //       logo_path: "tiktok_logo.png",
    //       duration: "May 2022 - Aug 2022",
    //       location: "San Francisco, USA",
    //       description:
    //         "Building new features on the backend recommendation system, specifically ranking algorithms for Ads that touch hundreds of millions of people around the world. Improving online and offline content ranking algorithms by performing hard sample data replays for training steps.",
    //       color: "#000000",
    //     },
    //     {
    //       title: "Data Science Research Intern",
    //       company: "Delhivery Pvt. Ltd.",
    //       company_url: "https://www.delhivery.com/",
    //       logo_path: "delhivery_logo.png",
    //       duration: "May 2019 - Sept 2019",
    //       location: "Gurgaon, Haryana",
    //       description:
    //         "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
    //       color: "#ee3c26",
    //     },
    //     {
    //       title: "Data Science Intern",
    //       company: "Intel Indexer LLC",
    //       company_url:
    //         "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
    //       logo_path: "intel_logo.jpg",
    //       duration: "Nov 2018 - Dec 2018",
    //       location: "Work From Home",
    //       description:
    //         "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
    //       color: "#0071C5",
    //     },
    //   ],
    // },
    // {
    //   title: "Volunteerships",
    //   experiences: [
    //     {
    //       title: "Google Explore ML Facilitator",
    //       company: "Google",
    //       company_url: "https://about.google/",
    //       logo_path: "google_logo.png",
    //       duration: "June 2019 - April 2020",
    //       location: "Hyderabad, Telangana",
    //       description:
    //         "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
    //       color: "#4285F4",
    //     },
    //     {
    //       title: "Microsoft Student Partner",
    //       company: "Microsoft",
    //       company_url: "https://www.microsoft.com/",
    //       logo_path: "microsoft_logo.png",
    //       duration: "Aug 2019 - May 2020",
    //       location: "Hyderabad, Telangana",
    //       description:
    //         "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
    //       color: "#D83B01",
    //     },
    //     {
    //       title: "Mozilla Campus Captain",
    //       company: "Mozilla",
    //       company_url: "https://www.mozilla.org/",
    //       logo_path: "mozilla_logo.png",
    //       duration: "Oct 2019 - May 2020",
    //       location: "Kurnool, Andhra Pradesh",
    //       description:
    //         "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
    //       color: "#000000",
    //     },
    //     {
    //       title: "Developer Students Club Member",
    //       company: "DSC IIITDM Kurnool",
    //       company_url:
    //         "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
    //       logo_path: "dsc_logo.png",
    //       duration: "Jan 2018 - May 2020",
    //       location: "Kurnool, Andhra Pradesh",
    //       description:
    //         "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
    //       color: "#0C9D58",
    //     },
    //     {
    //       title: "Developer Program Member",
    //       company: "Github",
    //       company_url: "https://github.com/",
    //       logo_path: "github_logo.png",
    //       duration: "July 2019 - PRESENT",
    //       location: "Work From Home",
    //       description:
    //         "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
    //       color: "#181717",
    //     },
    //   ],
    // },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "AM.png",
    description:
      "I am available on social medias provided below. You can message me, I will reply within 24 hours. ",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
